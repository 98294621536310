import * as React from "react";
import '../../styles/customNavigation.scss';
import logo from '../../images/Gesellschaft Einigkeit V2.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Cookies from 'universal-cookie';
import Button from 'react-bootstrap/Button';

const cookies = new Cookies();

function clearCookie() {
    cookies.remove('loggedInStatus');
    window.location.reload();
}

export function HeaderNavigation() {
    return (
        <div>
            <Navbar fixed="top" collapseOnSelect className='navigation-style' expand="lg" variant="dark">
                <Container>
                    <Navbar.Brand href="/"><img
                        src={logo}
                        width="100"
                        height="40"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/">Aktuell</Nav.Link>
                            <NavDropdown title="Der Verein" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/DerVerein">Vereinsinformation</NavDropdown.Item>
                                <NavDropdown.Item href="/EhrenAdel">
                                    Ehrennadelträger
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/Veranstaltungen">Veranstaltungen</Nav.Link>
                            <NavDropdown title="Medien" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/Downloads">Downloads</NavDropdown.Item>
                                <NavDropdown.Item href="/Galerie">Galerie</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Über uns" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/Impressum">Impressum</NavDropdown.Item>
                                <NavDropdown.Item href="/Nutzungsbedingungen">Allgemeine Nutzungsbediungen</NavDropdown.Item>
                                <NavDropdown.Item href="/Datenschutz">Erklärung zum Datenschutz</NavDropdown.Item>
                            </NavDropdown>
                            {cookies.get('loggedInStatus') === 'true' &&
                            <Nav.Link href="/MainMenu">Hauptmenü</Nav.Link>
                            }
                        </Nav>
                        
                        {cookies.get('loggedInStatus') === 'true' &&
                            <Button onClick={() => clearCookie()} variant="warning">Logout</Button>
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}
export default HeaderNavigation;
