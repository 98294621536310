import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import '../../styles/customUnionContent.scss';

export function UnionPage() {
    return (
        <div>
            <Container>
                <Card className="text-alignment card-position">
                    <Card.Header className="custom-card-header">{"Der Verein"}</Card.Header>
                    <Card.Body className="custom-card-body">
                        <Card.Text>
                            <p>Die Gesellschaft &bdquo;Einigkeit&ldquo; wurde 1954 gegr&uuml;ndet, um die Initiative zu ergreifen, dem heimischen Brauchtum wieder&nbsp;</p>
                            <p>neue Impulse zu geben. Zun&auml;chst wurde neben den vaterst&auml;dtischen Festen in den Anfangsjahren eine Reihe</p>
                            <p>kultureller Veranstaltungen durchgef&uuml;hrt. Von Anfang an war es in der &bdquo;Einigkeit&ldquo; Tradition die Feste so zu feiern wie&nbsp;</p>
                            <p>sie fallen. Eine der vaterst&auml;dtischen Veranstaltung ist die Kirmes der Einigkeit, die bis &nbsp;heute in jedem Jahr am ersten</p>
                            <p>Wochenende im Oktober stattfindet.</p>
                            <p><br/></p>
                            <p>H&ouml;hepunkte in der Session &nbsp;ist die Teilnahme am Karnevalszug in Bergheim - Kenten - Zieverich. Karneval hat viel</p>
                            <p>mit Brauchtumspflege zu tun und wird als gemeinn&uuml;tzig anerkannt. Um diese zu erlangen wurde</p>
                            <p>die Karnevalsgesellschaft Einigkeit 1994 e. V. gegr&uuml;ndet. &nbsp;</p>
                            <p><br/></p>
                            <p>Auch in der heutigen Zeit werden viele Aktivit&auml;ten f&uuml;r das Miteinander der B&uuml;rger in der Kreisstadt Bergheim veranstaltet.&nbsp;</p>
                            <p>Die Veranstaltungen zu &bdquo;Vatertag&ldquo; und zum &bdquo;Sommerfest&ldquo; &nbsp;erfreuen sich gro&szlig;em Zuspruch in der Bev&ouml;lkerung.</p>
                            <p><br/></p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}
export default UnionPage;
