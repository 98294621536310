import * as React from 'react';

/**
 * Update Newsentry by ID
 * @param newsEntryString 
 * @param newsTitle 
 * @param date 
 * @param NID 
 * @returns Status
 */
export default async function createNews(newsEntryString: any, newsTitle: string, date: any, NID: any) {
    const params = {
      CONTENT: newsEntryString,
      TITLE: newsTitle,
      DATE: date,
      NID: NID
    };
    await fetch('https://gesellschaft-einigkeit.de/api/updatenewsentry', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {'Content-Type': 'application/json'}
    }).then(function (response) {
    })  
    window.location.reload();
    return "works"
  }