import React, { useState } from 'react';
import CustomModal from './CustomModal';
import getAllNewsEntry from '../../API/getAllNewsEntry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import '../../styles/customNewsEntryTalbeContent.scss'
import Editor from './QuillEditor';
/* import '../../styles/customNewsEntryTalbeContent.scss'; */
import moment, { utc } from 'moment';
import Stack from 'react-bootstrap/Stack';
import postDeleteNewsEntry from '../../API/postDeleteNewsEntry';
import postActivateNewsEntry from '../../API/postActivateNewsEntry';
import UpdateModal from './UpdateModal';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';



export default function NewsEntryTableView() {
    let glogablTitle: any
    const [visibility, setvisibility] = useState(false);
    const [stateTitle, setTitle] = useState("");
    const [Content, setContent] = useState("");
    const [NIDState, setNIDState] = useState(0);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    function setEditorInformation(Title: string, content: any, NID: any) {
        setContent(content);
        setTitle(Title);
        setvisibility(true);
        setNIDState(NID);
    }
    function deleteCheck(NID: any) {
        setNIDState(NID);
        handleShow();
    }

    function generateTR() {
        let allNewsEntrys: any = []
        allNewsEntrys = getAllNewsEntry()
        allNewsEntrys.sort((a: any, b: any) => b.creationDate.localeCompare(a.creationDate));
        let arr = [];
        for (let index = 0; index < allNewsEntrys.length; index++) {
            arr.push({
                Optionen: <div>

                    <FontAwesomeIcon onClick={() => deleteCheck(allNewsEntrys[index].NID)} icon={faTrashCan} className="icon-padding" />
                    <FontAwesomeIcon onClick={() => setEditorInformation(allNewsEntrys[index].Title, allNewsEntrys[index].Content, allNewsEntrys[index].NID)} icon={faPenToSquare} className="icon-padding" />
                    <FontAwesomeIcon onClick={() => postActivateNewsEntry(allNewsEntrys[index].NID, moment().format('YYYY-MM-DD HH:mm:ss'))} icon={faCircleCheck} className="icon-padding" /></div>,
                NewsTitel: allNewsEntrys[index].Title,
                Erstellungsdatum: moment(allNewsEntrys[index].creationDate).format('DD.MM.YYYY HH:mm'),
                NewsID: allNewsEntrys[index].NID
            });
        }
        return arr;
    }

    let stockFilter;
    let originFilter;
    const columns = [{
        dataField: 'Optionen',
        text: 'Optionen'
    }, {
        dataField: 'NewsTitel',
        text: 'News Titel',
        filter: textFilter({
            getFilter: filter => {
                stockFilter = filter;
            }
        })
    }, {
        dataField: 'Erstellungsdatum',
        text: 'Erstellungsdatum',
        filter: textFilter({
            getFilter: filter => {
                originFilter = filter;
            }
        })
    },
    {
        dataField: 'NewsID',
        text: 'ID'

    }];
    const updateModalProps = {
        content: Content,
        NID: NIDState,
        title: "Bearbeiten eines Newseintrages",
        contentTitle: stateTitle,
        visibilty: visibility,
        hideVisibilty: () => setvisibility(false),
    }
    const tr = generateTR()
    return (<div>
        <Stack gap={2}>
            <div className="new-Left">
                <CustomModal
                    title={"Erstellen eines Newsbeitrages"}
                    editor={<Editor></Editor>}
                />
            </div>
            <BootstrapTable
                keyField='id'
                data={tr}
                columns={columns}
                pagination={paginationFactory({ sizePerPage: 10 })}
                filter={filterFactory()}
            />
        </Stack>
        <Modal show={show} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Löschen eines Newseintrages</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sind Sie sich sicher das Sie diesen Eintrag löschen wollen ?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() =>handleClose()}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={() => postDeleteNewsEntry(NIDState)}>
                    Löschen
                </Button>
            </Modal.Footer>
        </Modal>
        <UpdateModal
            {...updateModalProps}
        >
        </UpdateModal>
    </div>
    );
}