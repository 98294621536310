import * as React from 'react';
import Container from 'react-bootstrap/Container';
import { INewsCardProps } from '../../Interface/props/INewsCardProps';
import Card from 'react-bootstrap/Card';
import '../../styles/customNewsPageContent.scss';

export default class NewsCard extends React.Component<INewsCardProps, {}>{
    public render(): React.ReactElement<INewsCardProps> {
        return (
            <div className="card-padding" id={this.props.id}>
                <Card className="resizeable_Image" id={this.props.id}>
                    <Card.Header id={this.props.id} className="custom-card-header">{this.props.title}</Card.Header>
                    <Card.Body id={this.props.id} className="custom-card-body">
                        <Card.Text id={this.props.id} >
                            <div dangerouslySetInnerHTML={{
                                __html: this.props.content
                            }} />
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}