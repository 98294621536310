import { useEffect } from 'react';
import React, { useState } from 'react';
import { useQuill } from 'react-quilljs';
import ReactQuill from "react-quill";
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';
import Button from 'react-bootstrap/Button';
import './styles.css';
import postUpdateNewsEntry from '../../API/postUpdateNewsEntry';
import '../../styles/customEditorStyle.scss';
import moment, { utc } from 'moment';
import Form from 'react-bootstrap/Form';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
  ],
};

export default function UpdateQuillEditor(props: any) {
  const [title, setTitle] = useState("");
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      blotFormatter: {},
    }
  });

  if (Quill && !quill) {
    // const BlotFormatter = require('quill-blot-formatter');
    Quill.register('modules/blotFormatter', BlotFormatter);
  }
  const [HtmlText, setHtmlText] = useState("");

  useEffect(() => {
    if (quill) {
      setTitle(props.Title)
      quill.clipboard.dangerouslyPasteHTML("<div>" + props.initalHTML + "</div>");
      quill.on('text-change', (delta: any, oldContents: any) => {
        setHtmlText(quill.root.innerHTML);
        let currrentContents = quill.getContents();
        console.log(currrentContents.diff(oldContents));
      });
    }
  }, [quill, Quill]);

  function saveEntry() {
    const post = postUpdateNewsEntry(HtmlText, title, moment().format('YYYY-MM-DD HH:mm:ss'), props.NID);
  }

  return (
      <div className="editor-height">
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Titel</Form.Label>
          <Form.Control onChange={ (event: any) => setTitle(event.target.value)} value={title} type="text" placeholder="Titel" />
        </Form.Group>
      </Form>
        <div ref={quillRef} />
        <Button onClick={saveEntry}>Save</Button>
      </div>
  );
};