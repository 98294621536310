import React, { useState } from 'react';
import CustomModal from './CustomModal';
import Container from 'react-bootstrap/Container';
import Cookies from 'universal-cookie';
import { Navigate } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/customMenuContent.scss';
import NewsEntryTableView from './NewsEntryTableView';
import GalerieTableView from './GalerieTableView';
const cookies = new Cookies();

export function MainMenu() {
    const [view, setView] = useState("");
    if (cookies.get('loggedInStatus') === 'true') {
        if (view === "Galerie ÜBersicht") {
            return (
                <div className="body">
                    <Row>
                        <Col sm={2} className="menu">
                            <ul>
                                <li className="li-style" onClick={() => setView("News Übersicht")}><a href="#home">News Übersicht</a></li>
                                <li onClick={() => setView("Galerie ÜBersicht")}><a className="active">Galerie übersicht</a></li>
                            </ul>
                        </Col>
                        <Col sm={10}><Container fluid>
                            <div>
                                <GalerieTableView></GalerieTableView>
                            </div>
                        </Container></Col>
                    </Row>
                </div>
            );
        }
        else {
            return (
                <div className="body">
                    <Row>
                        <Col sm={2} className="menu">
                            <ul>
                                <li onClick={() => setView("News Übersicht")}><a className="active">News Übersicht</a></li>
                                <li onClick={() => setView("Galerie ÜBersicht")}><a href="">Galerie übersicht</a></li>
                            </ul>
                        </Col>
                        <Col sm={10}><Container fluid>
                            <div>
                                <NewsEntryTableView></NewsEntryTableView>
                            </div>
                        </Container></Col>
                    </Row>
                </div>
            );
            
        }
    }
    else {
        return (
            <div>
                <Navigate to="/Login" />
            </div>
        )
    }
}
export default MainMenu;
