import React, { useEffect, useState } from "react";
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';
import '../../styles/customNavigation.scss';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';




export default function Galerie(props: any) {
    function createGalleryItems() {
        let arr = []
        for (let index = 0; index < props.src.length; index++) {
            arr.push({
                original: props.src[index].path,
                thumbnail: props.src[index].path
            })

        }
        return arr
    }
    const galleryItems = createGalleryItems();
    return (
        <div>
            <Container>
                <Card className="text-alignment card-position">
                    <Card.Header className="custom-card-header">{"Bildergalerie"}</Card.Header>
                    <Card.Body className="custom-card-body">
                        <Card.Text >
                            <div>
                                <ImageGallery items={galleryItems} />
                            </div>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}