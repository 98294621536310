import * as React from "react";
import '../../styles/customNavigation.scss';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

export function Impressum() {
    console.log("run");
    return (
        <div>
            <Container>
                <Card className="text-alignment card-position">
                    <Card.Header className="custom-card-header">{"Impressum"}</Card.Header>
                    <Card.Body className="custom-card-body">
                        <Card.Text >
                            <div>
                                <p>Alle hier verwendeten Namen, Begriffe, Zeichen und Grafiken k&ouml;nnen Marken- oder Warenzeichen im Besitze ihrer rechtlichen Eigent&uuml;mer sein. Die Rechte aller erw&auml;hnten und benutzten Marken- und Warenzeichen liegen ausschlie&szlig;lich bei deren Besitzern.</p>
                                <p>&nbsp;</p>
                                <p><strong>Gesellschaft &quot;Einigkeit&quot; 1954 e.V.</strong></p>
                                <p><strong>Martin Jansen</strong></p>
                                <p><strong>1. Vorsitzender</strong></p>
                                <p>Wiesenstra&szlig;e 1a</p>
                                <p>50126 Bergheim</p>
                                <p>Tel.: 02271 44315</p>
                                <p>einigkeit.jansen@web.de</p>
                                <p>&nbsp;</p>
                                <p><strong>Rechtliche Angaben</strong></p>
                                <p>Vertretungsberechtigt: Martin Jansen</p>
                                <p>Inhaltlich verantwortlich gem&auml;&szlig; TMG: Martin Jansen</p>
                                <p>&nbsp;</p>
                                <p><strong>Technische Angaben</strong></p>
                                <p>Technische Verantwortliche:</p>
                                <p>Michael Jansen</p>
                                <p>Christopher Aussem</p>
                            </div>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}
export default Impressum;
