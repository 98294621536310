import * as React from 'react';

/**
 * Delete Selected Newsentry
 * @param newsID 
 */
export default async function postDeleteNewsEntry(newsID: any) {
    const params = {
      NID: newsID,
    };
    await fetch('https://gesellschaft-einigkeit.de/api/deletenewsentry', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {'Content-Type': 'application/json'}
    }).then(function (response) {
    })
    window.location.reload();
}