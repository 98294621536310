import * as React from 'react';


/**
 * Update NewsEntry Date
 * @param newsID 
 * @param date 
 */
export default async function postActivateNewsEntry(newsID: any, date: any) {
    const params = {
      NID: newsID,
      DATE: date
    };
    await fetch('https://gesellschaft-einigkeit.de/api/updatenewsentrydate', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {'Content-Type': 'application/json'}
    }).then(function (response) {
    })
    window.location.reload();
}