import * as React from 'react';
import Container from 'react-bootstrap/Container';
import NewsCard from './NewsCard';
import logo from '../../images/Gesellschaft Einigkeit V2.png';
import Card from 'react-bootstrap/Card';
import { INewsPageProps } from '../../Interface/props/INewsPageProps';
import { INewsPageState } from '../../Interface/states/INewsPageState';
import '../../styles/customNewsPageContent.scss';

export default class NewsPage extends React.Component<INewsPageProps, INewsPageState>{
    constructor(props: any) {
        super(props);
        this.state = {
            card: null,
        };
    }
    componentDidMount() {
        this.setState({ card: this.createCard() });
    }
    componentDidUpdate(prevProps: INewsPageProps) {
        if (this.props.content !== prevProps.content) {
            this.setState({ card: this.createCard() });
        }
    }
    public render(): React.ReactElement<INewsPageProps> {
        return (
            <div>
                <div className="card-padding" key={"WelcomeCard"}>
                <Card className="resizeable_Image" key={"WelcomeCard"}>        
                    <Card.Header key={"WelcomeCard"} className="custom-card-header">Herzlich Willkommen</Card.Header>
                    <div className='custom-card-body'>
                    <Card.Img className='image-Size custom-card-body' variant="top" src={logo} />
                    </div>
                </Card>
            </div>
                <Container>
                    {this.state.card}
                </Container>
                <a href="/Archiv">Archiv</a>
            </div>
        );
    }
    /**
     * Create NewsCards from metadata
     * @returns News Card
     */
    private createCard() {
        let cards = [];
        let array = [];
        array = this.props.content;
        for (let cardValue of this.props.content) {
            cards.push(
                <NewsCard
                    id={"N" +cardValue.NID}
                    title={cardValue.Title}
                    content={cardValue.Content}
                />
            );
        }
        return cards;
    }
}