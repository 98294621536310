import * as React from 'react';
import Container from 'react-bootstrap/Container';
import NewsCard from './NewsCard';
import { INewsPageProps } from '../../Interface/props/INewsPageProps';
import { INewsPageState } from '../../Interface/states/INewsPageState';

export default class NewsArchiv extends React.Component<INewsPageProps, INewsPageState>{
    constructor(props: any) {
        super(props);
        this.state = {
            card: null,
        };
    }
    componentDidMount() {
        this.setState({ card: this.createCard() });
    }
    componentDidUpdate(prevProps: INewsPageProps) {
        if (this.props.content !== prevProps.content) {
            this.setState({ card: this.createCard() });
        }
    }
    public render(): React.ReactElement<INewsPageProps> {
        return (
            <div>
                <Container>
                    {this.state.card}
                </Container>
            </div>
        );
    }
    private createCard() {
        let cards = [];
        let array = [];
        array = this.props.content;
        for (let cardValue of this.props.content) {
            cards.push(
                <NewsCard
                    id={"A" + cardValue.NID}
                    title={cardValue.Title}
                    content={cardValue.Content}
                />
            );
        }
        return cards;
    }
}