import React, {useState} from 'react';
import { sha256, sha224 } from 'js-sha256';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


/**
 * Summit Login Data and set Session cookie after return
 * @param username 
 * @param password 
 */
export default async function postLogin(username: string, password: string) {
let returnvalue
  const params = {
    username: username,
    password: sha256(password),
  };
  const result = await fetch('https://gesellschaft-einigkeit.de/api/submitlogin', {
    method: 'POST',
    body: JSON.stringify(params),
    headers: { 'Content-Type': 'application/json' }
  });
  returnvalue = await result.json() 
  if(returnvalue == false){
    cookies.set('loggedInStatus', Boolean(returnvalue), { path: '/' });
    alert("Die anmeldung war Fehlerhaft bitte überprüfen Sie ihren Nutzernahmen/ Passwort")
  }
  else{ 
    cookies.set('loggedInStatus', Boolean(returnvalue), { path: '/' });
    window.location.reload();
  }  
}
