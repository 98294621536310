import React, { useState } from 'react';
import CustomModal from './CustomModal';
import getGallery from '../../API/getGallery';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import '../../styles/customNewsEntryTalbeContent.scss'
import Editor from './QuillEditor';
/* import '../../styles/customNewsEntryTalbeContent.scss'; */
import Stack from 'react-bootstrap/Stack';


function generateTR() {
    let allGalleryEntrys = []
    allGalleryEntrys = getGallery()
    let arr = [];
    for (let index = 0; index < allGalleryEntrys.length; index++) {
        arr.push({
            Image: <div><img height="100" width="150" src={allGalleryEntrys[index].path}></img></div>,
            NewsID: index + 1,
        });

    }
    return arr;
}

export default function NewsEntryTableView() {
    let stockFilter;
    let originFilter;
    const columns = [{
        dataField: 'Image',
        text: 'Bild'
    },
    {
        dataField: 'NewsID',
        text: 'ID'

    }];

    const tr = generateTR()
    return (<div>
        <Stack gap={2}>
            <div className="new-Left">
            </div>
            <BootstrapTable
                keyField='id'
                data={tr}
                columns={columns}
                pagination={paginationFactory({ sizePerPage: 5 })}
                filter={filterFactory()}
            />
        </Stack>
    </div>
    );
}