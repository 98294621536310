import * as React from 'react';
import {parse, stringify, toJSON, fromJSON} from 'flatted';

/**
 * Submit New Newsentrys
 * @param newsEntryString 
 * @param newsTitle 
 * @param date 
 * @returns Status
 */
export default async function createNews(newsEntryString: any, newsTitle: string, date: any) {
    const params = {
      newsEntryString: newsEntryString,
      newsTitle: newsTitle,
      date: date
    };
    await fetch('https://gesellschaft-einigkeit.de/api/savenewsentry', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {'Content-Type': 'application/json'}
    }).then(function (response) {
    })
    window.location.reload();
    return "works"
  }
