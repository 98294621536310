import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import logo from './logo.svg';
import './App.css';
import './styles/customBootstrap.scss';
import HeaderNavigation from './components/Navigation/HeaderNavigation';
import FooterNavigation from './components/Navigation/FooterNavigation';
import Impressum from './components/AboutUsContent/Impressum';
import Veranstaltungen from './components/Events/Veranstaltungen';
import AllgemeineNutzungsbedingung from './components/AboutUsContent/AllgemeineNutzungsbedingung';
import Container from 'react-bootstrap/Container';
import { Routes, Route, Link, Navigate } from "react-router-dom";
import getNewsEntry from './API/getNewsEntry';
import allnewsentry from './API/getAllNewsEntry';
import NewsPage from './components/NewsPage/NewsPage';
import NewsArchiv from './components/NewsPage/NewsArchiv';
import MainMenu from './components/NewsEditor/MainMenu';
import UnionPage from './components/Union/Union';
import EhrenAdel from './components/Union/EhrenAdel';
import Datenschutz from './components/AboutUsContent/Datenschutz';
import Login from './components/Login/Login';
import Cookies from 'universal-cookie';
import Downloads from './components/Gallerie/Downloads';
import getGallery from './API/getGallery';
import Galerie from './components/Gallerie/Galerie';


function App() {
  return (
    <div className="App">
      <HeaderNavigation />
      <Container className="main-Container">
        <Routes>
          <Route path="" element={<NewsPage content={getNewsEntry()} />}>
          </Route>
          <Route path="Archiv" element={<NewsArchiv content={allnewsentry()} />}></Route>
          <Route path="Impressum" element={<Impressum />} />
          <Route path="Nutzungsbedingungen" element={<AllgemeineNutzungsbedingung />} />
          <Route path="Datenschutz" element={<Datenschutz />} />
          <Route path="Veranstaltungen" element={<Veranstaltungen />} />

          <Route path="DerVerein" element={<UnionPage />} />
          <Route path="EhrenAdel" element={<EhrenAdel />} />
          <Route path="Login" element={<Login />} />
          <Route path="Downloads" element={<Downloads />} />
          <Route path="Galerie" element={<Galerie src={getGallery()} />} />
        </Routes>
      </Container>
      <Routes>
        <Route path="MainMenu" element={<MainMenu />} />
      </Routes>
      <FooterNavigation />
    </div>
  );
}

export default App;
