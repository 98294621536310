import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import '../../styles/customAboutUsContent.scss';
export default function AllgemeineNutzungsbedingung() {
    return (
        <div>
            <Container>
                <Card className="text-alignment card-position">
                    <Card.Header className="custom-card-header">{"Allgemeine Nutzungsbedingungen für die Gesellschaft Einigkeit Website"}</Card.Header>
                    <Card.Body className="custom-card-body">
                        <Card.Text >
                            <p><span>1. Informationen zum Urheberrecht</span><br />Alle Informationen dieser Web-Seite werden wie angegeben ohne Anspruch auf Richtigkeit, Vollst&auml;ndigkeit oder Aktualit&auml;t zur Verf&uuml;gung gestellt.<br />Wenn nicht ausdr&uuml;cklich anderweitig in dieser Publikation zu verstehen gegeben, und zwar in Zusammenhang mit einem bestimmten Ausschnitt, einer Datei, oder einem Dokument, ist jedermann dazu berechtigt, dieses Dokument anzusehen, zu kopieren, zu drucken und zu verteilen, unter den folgenden Bedingungen:<br />Das Dokument darf nur f&uuml;r nichtkommerzielle Informationszwecke genutzt werden. Jede Kopie dieses Dokuments oder eines Teils davon muss diese urheberrechtliche Erkl&auml;rung und das urheberrechtliche Schutzzeichen des Betreibers enthalten. Das Dokument, jede Kopie des Dokuments oder eines Teils davon d&uuml;rfen nicht ohne schriftliche Zustimmung des Betreibers ver&auml;ndert werden. Der Betreiber beh&auml;lt sich das Recht vor, diese Genehmigung jederzeit zu widerrufen, und jede Nutzung muss sofort eingestellt werden, sobald eine schriftliche Bekanntmachung seitens des Betreibers ver&ouml;ffentlicht wird.</p>
                            <p><span>2. Vertragliche Zusicherungen und Verzichterkl&auml;rungen</span><br />Die Website steht Ihnen - soweit nicht anders vereinbart - kostenlos zur Verf&uuml;gung. Die Betreiber &uuml;bernehmen keinerlei Gew&auml;hr f&uuml;r Richtigkeit der enthaltenen Informationen, Verf&uuml;gbarkeit der Dienste, Verlust von abgespeicherten Daten oder Nutzbarkeit f&uuml;r irgendeinen bestimmten Zweck.<br />Die Betreiber haften auch nicht f&uuml;r Folgesch&auml;den, die auf einer Nutzung des Angebotes beruhen.<br />Soweit ein Haftungsausschluss nicht in Betracht kommt, haften die Betreiber lediglich f&uuml;r grobe Fahrl&auml;ssigkeit und Vorsatz. Produkt- und Firmennamen sind Marken der jeweiligen Eigent&uuml;mer und werden auf diesen Seiten ausschlie&szlig;lich zu Informationszwecken eingesetzt.<br />Diese Publikation k&ouml;nnte technische oder andere Ungenauigkeiten enthalten oder Schreib- oder Tippfehler. Von Zeit zu Zeit werden der vorliegenden Information &auml;nderungen hinzugef&uuml;gt; diese &auml;nderungen werden in neuen Ausgaben der Publikation eingef&uuml;gt. Der Betreiber kann jederzeit Verbesserungen und/oder Ver&auml;nderungen an den Angeboten vornehmen, die in dieser Publikation beschrieben werden.</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p><span>3. Meinungs&auml;u&szlig;erungen bei Kommentaren und im Forum</span><br />Aufgrund der sich st&auml;ndig ver&auml;ndernden Inhalte bei Kommentaren und im Forum ist es dem Betreiber nicht m&ouml;glich, alle Beitr&auml;ge l&uuml;ckenlos zu sichten, inhaltlich zu pr&uuml;fen und die unmittelbare aktive Kontrolle dar&uuml;ber auszu&uuml;ben. Es wird keine Verantwortung f&uuml;r den Inhalt, die Korrektheit und die Form der eingestellten Beitr&auml;ge &uuml;bernommen.</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p><span>3a. Spezielle Bestimmungen f&uuml;r angemeldete Nutzer</span><br />Mit der Anmeldung erkl&auml;rt sich der Nutzer - nachfolgend Mitglied gegen&uuml;ber dem Betreiber mit folgenden Nutzungsbedingungen einverstanden:<br />Mitglieder, die sich an Diskussionsforen und Kommentaren beteiligen, verpflichten sich dazu,</p>
                            <p>&nbsp;</p>
                            <ul>
                                <li>1. Sich in Ihren Beitr&auml;gen jeglicher Beleidigungen, strafbarer Inhalte, Pornographie und grober Ausdrucksweise zu enthalten,</li>
                                <li>2. Die alleinige Verantwortung f&uuml;r die von ihnen eingestellten Inhalte zu tragen, Rechte Dritter (insbesondere Marken-, Urheber- und Pers&ouml;nlichkeitsrechte) nicht zu verletzen und die Betreiber von durch ihre Beitr&auml;ge ausgel&ouml;sten Anspr&uuml;chen Dritter vollst&auml;ndig freizustellen.</li>
                                <li>3. Weder in Foren noch in Kommentaren Werbung irgendwelcher Art einzustellen oder Foren und Kommentare zu irgendeiner Art gewerblicher T&auml;tigkeit zu nutzen. Insbesondere gilt das f&uuml;r die Ver&ouml;ffentlichung von 0900-Rufnummern zu irgendeinem Zweck.</li>
                            </ul>
                            <p>&nbsp;</p>
                            <p>Es besteht keinerlei Anspruch auf Ver&ouml;ffentlichung von eingereichten Kommentaren oder Forenbeitr&auml;gen. Die Betreiber behalten sich vor, Kommentare und Forenbeitr&auml;ge nach eigenem Ermessen zu editieren oder zu l&ouml;schen. Bei Verletzungen der Pflichten unter 1), 2) und 3) behalten sich die Betreiber ferner vor, die Mitgliedschaft zeitlich begrenzt zu sperren oder dauernd zu l&ouml;schen.</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p><span>4. Einreichen von Beitr&auml;gen und Artikeln</span><br />Soweit das Mitglied von der M&ouml;glichkeit Gebrauch macht, eigene Beitr&auml;ge f&uuml;r redaktionellen Teil einzureichen, gilt Folgendes:<br />Voraussetzung f&uuml;r das Posten eigener Beitr&auml;ge ist, dass das Mitglied seinen vollst&auml;ndigen und korrekten Vor- und Nachnamen in sein Benutzerprofil eingetragen hat oder nach dem Einreichen des Artikels dort eintr&auml;gt. Mit dem dort eingetragenen Namen wird der eingereichte Beitrag bei Ver&ouml;ffentlichung (&ouml;ffentlich) gekennzeichnet.<br />Das Mitglied gibt f&uuml;r alle Beitr&auml;ge, die von ihm oder ihr zuk&uuml;nftig eingereicht werden, folgende Erkl&auml;rungen ab:</p>
                            <p>&nbsp;</p>
                            <ul>
                                <li>1. Das Mitglied versichert, das die eingereichten Beitr&auml;ge frei von Rechten Dritter, insbesondere Urheber-, Marken- oder Pers&ouml;nlichkeitsrechten sind. Dies gilt f&uuml;r alle eingereichten Beitr&auml;ge und Bildwerke.</li>
                                <li>2. Das Mitglied r&auml;umt den Betreibern ein uneingeschr&auml;nktes Nutzungsrecht an den eingereichten Beitr&auml;gen ein. Dieses umfasst die Ver&ouml;ffentlichung im Internet sowie auf anderen Internetservern, in Newslettern, Printmedien und anderen Publikationen.</li>
                                <li>3. Eingereichte Beitr&auml;ge werden auf Verlangen des Mitgliedes per Email an die Adresse des&nbsp;<a href="http://gesellschaft-einigkeit.de/index.php?option=com_impressum&amp;view=impressum&amp;Itemid=194">Webmasters</a>&nbsp;wieder gel&ouml;scht bzw. anonymisiert. Die L&ouml;schung bzw. Anonymisierung erfolgt innerhalb von 7 Tagen nach der Mitteilung. F&uuml;r Folgesch&auml;den, die dem Mitglied aus der versp&auml;teten L&ouml;schung des Beitrages entstehen haften die Betreiber nur insoweit, als sie nicht auf einer Pflichtverletzung des Mitgliedes (oben unter 1), 2) und 3) ) und soweit sie dar&uuml;ber hinaus auf grobem Verschulden oder Vorsatz der Betreiber beruhen. Wir weisen in diesem Zusammenhang ausdr&uuml;cklich darauf hin, dass diese Website regelm&auml;&szlig;ig von Suchmaschinen indexiert wird, und dass wir keinen Einfluss darauf haben, ob, wo und wie lange bei uns ver&ouml;ffentlichte Beitr&auml;ge m&ouml;glicherweise auch nach L&ouml;schung in Datenbanken von Suchmaschinen und Webkatalogen gespeichert werden und abrufbar sind.</li>
                                <li>4. Es besteht keinerlei Anspruch auf Speicherung, Ver&ouml;ffentlichung oder Archivierung der eingereichten Beitr&auml;ge. Die Betreiber behalten sich vor, eingereichte Beitr&auml;ge ohne Angabe von Gr&uuml;nden nicht zu ver&ouml;ffentlichen, vor Ver&ouml;ffentlichung zu editieren oder nach Ver&ouml;ffentlichung nach freiem Ermessen wieder zu l&ouml;schen.</li>
                                <li>5. Durch die Ver&ouml;ffentlichung eingereichter Beitr&auml;ge entstehen keinerlei Verg&uuml;tungsanspr&uuml;che (Honorare, Lizenzgeb&uuml;hren, Aufwendungsentsch&auml;digungen oder &auml;hnliches) des Mitgliedes gegen&uuml;ber dem Betreiber der Website. Die Mitarbeit ist ehrenamtlich (unentgeltlich).</li>
                            </ul>
                            <p>&nbsp;</p>
                            <p><span>5. Erkl&auml;rung zum Datenschutz (Privacy Policy)</span><br />Sofern innerhalb des Internetangebotes die M&ouml;glichkeit zur Eingabe pers&ouml;nlicher oder gesch&auml;ftlicher Daten genutzt wird, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdr&uuml;cklich freiwilliger Basis. Die Inanspruchnahme unseres Dienstes ist - soweit technisch m&ouml;glich und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Weitere wichtige Informationen zum Thema Datenschutz finden sich in unserer&nbsp;<a href="http://gesellschaft-einigkeit.de/index.php?option=com_impressum&amp;Itemid=194&amp;view=datenschutz">Erkl&auml;rung zum Datenschutz (Privacy Policy)</a>.</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p><span>6. Registrierung und Passwort</span><br />Der Benutzer ist verpflichtet, die Kombination Benutzername/Passwort vertraulich zu behandeln und nicht an Dritte weiterzugeben. Bei Verdacht auf Missbrauch der Zugangsdaten ist der Betreiber zu informieren.</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p><span>7. Hinweis gem&auml;&szlig; Teledienstgesetz</span><br />F&uuml;r Internetseiten Dritter, auf die die dieses Angebot durch sog. Links verweist, tragen die jeweiligen Anbieter die Verantwortung. Der Betreiber ist f&uuml;r den Inhalt solcher Seiten Dritter nicht verantwortlich. Des Weiteren kann die Web-Seite ohne unser Wissen von anderen Seiten mittels sog. Links angelinkt werden. Der Betreiber &uuml;bernimmt keine Verantwortung f&uuml;r Darstellungen, Inhalt oder irgendeine Verbindung zu dieser Web-Seite in Web-Seiten Dritter. F&uuml;r fremde Inhalte ist der Betreiber nur dann verantwortlich, wenn von ihnen (d.h. auch von einem rechtswidrigen oder strafbaren Inhalt) positive Kenntnis vorliegt und es technisch m&ouml;glich und zumutbar ist, deren Nutzung zu verhindern. Der Betreiber ist nach dem Teledienstgesetz jedoch nicht verpflichtet, die fremden Inhalte st&auml;ndig zu &uuml;berpr&uuml;fen.</p>
                            <p>&nbsp;</p>
                            <p><span>Rechtswirksamkeit</span><br />Diese Allgemeinen Nutzungsbedingungen beziehen sich auf diese Website.<br />Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollst&auml;ndig entsprechen sollten, bleiben die &uuml;brigen Teile des Dokumentes in ihrem Inhalt und ihrer G&uuml;ltigkeit davon unber&uuml;hrt.</p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}