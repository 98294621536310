import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export default function Downloads() {
    const downloadPDF = () => {
        let alink = document.createElement('a');
        alink.href = "https://gesellschaft-einigkeit.de/backend/Downloads/Eintrittserkl%c3%a4rung_onlySEPA.pdf";
        alink.download = 'Eintrittserklärung_onlySEPA.pdf';
        alink.click();
    }
    
    return (
        <Card>
            <Card.Header>Download</Card.Header>
            <Card.Body>
                <Card.Title>Beitrittserklärung</Card.Title>
                <Card.Text>
                    <div>Beitrittserkl&auml;rung f&uuml;r die Gesellschaft Einigkeit 1954 e.V.</div>
                    <div>
                        <div>Eintrittserkl&auml;rung_onlySEPA.pdf</div>
                        <div>Adobe Acrobat Dokument 78.6 KB</div>
                    </div>
                </Card.Text>
                <Button type="submit" variant="dark" onClick={downloadPDF}>Download</Button>
            </Card.Body>
        </Card>
    )
}
