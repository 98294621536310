import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../styles/customModal.scss';

export default class CustomModal extends React.Component<{ editor: any, title: any }, { setShown: any }>{
    constructor(props: any) {
        super(props);
        this.state = {
            setShown: false,
        };
        this.handleShow = this.handleShow.bind(this);
    }

    private handleShow() {
        this.setState({ setShown: !this.state.setShown })
    }

    public render(): React.ReactElement<{ editor: any, title: any }> {
        return (
            <div>
                <Button variant="dark" style={{ float: 'left'}}onClick={() => this.handleShow()}>
                    Neu+
                </Button>
                <Modal
                    show={this.state.setShown} 
                    onHide={() => this.handleShow()}
                    aria-labelledby="example-custom-modal-styling-title"
                    fullscreen={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.editor}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.handleShow()}>Schließen</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}