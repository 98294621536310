import * as React from "react";
import '../../styles/customNavigation.scss';
import logo from '../../images/Gesellschaft Einigkeit V2.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

export function FooterNavigation() {
    return (
        <div>
            <Navbar fixed="bottom" className="footerStyle font-size-default">
                <Container>
                    <Nav className="justify-content-end">
                        <Navbar.Brand href="https://www.instagram.com/gesellschaft_einigkeit_bm/">
                            <FontAwesomeIcon icon={faInstagram} />
                        </Navbar.Brand>
                    </Nav>
                    <Navbar.Brand className="right-text " href="/">
                        <div>© Gesellschaft Einigkeit Ev</div>
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </div>
    )
}
export default FooterNavigation;
