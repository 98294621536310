import React, { useEffect, useState } from "react";
import postLogin from '../../API/postLogin';
import { Navigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import '../../styles/customNewsPageContent.scss';
const cookies = new Cookies();



export default function Login() {
    console.log(cookies.get('loggedInStatus'));
    const [usernameLog, setUsernameLog] = useState('');
    const [passwordLog, setPasswordRLog] = useState('');

    if (cookies.get('loggedInStatus') === 'false' || cookies.get('loggedInStatus') === undefined) {
        return (

            <div>
                <Card className="resizeable_Image">
                    <Card.Header id={"1"} className="custom-card-header">Login</Card.Header>
                    <Card.Body className="custom-card-body">
                        <Card.Text >
                            <Form>
                                <Form.Group className="mb-3" controlId="formGroupEmail">
                                    <Form.Label>Email Addresse</Form.Label>
                                    <Form.Control onChange={(e) => { setUsernameLog(e.target.value) }} type="email" placeholder="Enter email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupPassword">
                                    <Form.Label>Passwort</Form.Label>
                                    <Form.Control onChange={(e) => { setPasswordRLog(e.target.value) }} type="password" placeholder="Password" />
                                </Form.Group>
                                <Button onClick={() => postLogin(usernameLog, passwordLog)} variant="dark">
                                    Anmelden
                                </Button>
                            </Form>
                        </Card.Text>
                    </Card.Body>
                </Card>
                {/* <h1>Login</h1>
                <div>
                    <label>username</label>
                    <input type="email" onChange={(e) => { setUsernameLog(e.target.value) }}></input>
                </div>
                <div>
                    <label>password</label>
                    <input type="password" onChange={(e) => { setPasswordRLog(e.target.value) }}></input>
                </div>
                <button onClick={() => postLogin(usernameLog, passwordLog)}>Login</button> */}
            </div>
        );
    }
    else {
        return (
            <Navigate to="/MainMenu" />
        )
    }
}