import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UpdateQuillEditor from'./UpdateQuillEditor';
import '../../styles/customModal.scss';

export default class UpdateModal extends React.Component<{ content: any, NID: any, title: any, visibilty: any, hideVisibilty: any, contentTitle: any }, { setShown: any}>{
    constructor(props: any) {
        super(props);
        this.state = {
            setShown: false,
        };
        this.handleShow = this.handleShow.bind(this);
    }

    private handleShow() {
    }

    public render(): React.ReactElement<{ editor: any, title: any, visibilty: any, hideVisibilty: any, contentTitle: any }> {
        return (
            <div>
                <Modal
                    show={this.props.visibilty} 
                    onHide={() => this.props.hideVisibilty()}
                    aria-labelledby="example-custom-modal-styling-title"
                    fullscreen={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <UpdateQuillEditor initalHTML={this.props.content} Title={this.props.contentTitle} NID={this.props.NID}></UpdateQuillEditor>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.handleShow()}>Schließen</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}