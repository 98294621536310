import * as React from 'react';
import '../../styles/customEventsContent.scss';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import logo from '../../images/Gesellschaft Einigkeit V2.png';
import Hubertusmarkt from '../../images/hubertusmarkt-1.jpg';
import Sommerfest from '../../images/1658428381.jpg';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
export default function Veranstaltungen() {
    return (
        <div>
            <Container>
                <Card className="text-alignment card-position">
                    <Card.Header className="custom-card-header">{"Veranstaltungen"}</Card.Header>
                    <Carousel className='carousel-style'>
                        <Carousel.Item interval={2000}>
                            <img
                                className="d-block w-100 image-size"
                                src={Hubertusmarkt}
                                alt="First slide"
                            />
                            <Carousel.Caption className="image-text">
                                <h2>Kirmes</h2>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={2000}>
                            <img
                                className="d-block w-100 image-size"
                                src={Sommerfest}
                                alt="Second slide"
                            />
                            <Carousel.Caption className="image-text">
                                <h2>Sommerfest</h2>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                    <Card.Body className="custom-card-body">
                        <Accordion className="custom-accordion-style">
                            <Accordion.Item className="custom-accordion-style" eventKey="0">
                                <Accordion.Header className="custom-accordion-header-style">*** Kirmes ***</Accordion.Header>
                                <Accordion.Body className="custom-accordion-style">
                                    Mit der Kirmes im Jahre 1954 fing alles an. Diese findet - bis heute - immer am 1. Wochenende im Oktober statt und
                                    erfreut sich großer Beliebtheit.
                                </Accordion.Body>
                            </Accordion.Item >
                            <Accordion.Item className="custom-accordion-style" eventKey="1">
                                <Accordion.Header className="custom-accordion-header-style">*** Sommerfest ***</Accordion.Header>
                                <Accordion.Body className="custom-accordion-style">
                                    Ebenfalls im Vereinsheim führen wir immer am 1. Wochenende im August unser Sommerfest durch. Bei bester
                                    Bewirtung und Sonnenschein, stellt sich gute Laune von selbst ein; rund um immer eine gelungene Sache.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className="custom-accordion-style" eventKey="2">
                                <Accordion.Header className="custom-accordion-header-style">*** Vatertag - Christi Himmelfahrt *** </Accordion.Header>
                                <Accordion.Body className="custom-accordion-style">
                                    Alljährlich feiern wir den Vatertag mit unseren Mitgliedern, Bekannten und Freunden des Vereins.  Kommen Sie auch mal vorbei.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className="custom-accordion-style" eventKey="3">
                                <Accordion.Header className="custom-accordion-header-style">*** Großer Karnevalszug durch die Ortsteile ***</Accordion.Header>
                                <Accordion.Body className="custom-accordion-style">
                                    Hier wirken wir in der Organisation mit und nehmen aktiv am "Zug" teil.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}