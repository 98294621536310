import * as React from 'react';
const fetch = require('sync-fetch');

/**
 * Request Images from specific Server path
 * @returns Images
 */

export default function getGallery() {
    const metadata = fetch('https://gesellschaft-einigkeit.de/api/gallery', {
        headers: {
            Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
        }
    }).json();
    return metadata
}