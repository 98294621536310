import * as React from 'react';
const fetch = require('sync-fetch');


/**
 * Request newest 3 Newsentrys
 * @returns Main 3 News
 */
export default function getNewsEntry() {
    const metadata = fetch('https://gesellschaft-einigkeit.de/api/newsentry', {
        headers: {
            Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
        }
    }).json();
    let LOC_newsEntry = [];
    for (let index = 0; index < metadata.length; index++) {
        LOC_newsEntry.push({ Content: metadata[index].Content, Title: metadata[index].Title, NID: metadata[index].NID, creationDate: metadata[index].creationDate });
    }
    return LOC_newsEntry;
}