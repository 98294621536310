import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Figure from 'react-bootstrap/Figure';
import Stack from 'react-bootstrap/Stack';
import '../../styles/customUnionContent.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import brilliImage from '../../images/brilli.jpg'
import rubinImage from '../../images/rubin.jpg'
import saphierImage from '../../images/saphier.jpg'

export function EhrenAdel() {
    return (
        <div>
            <Container>
                <Card className="text-alignment card-position">
                    <Card.Header className="custom-card-header">{"Ehrenadelsträger"}</Card.Header>
                    <Card.Body className="custom-card-body">
                        <Card.Text className="center-content">
                            <Stack gap={3} className='mx-left'>
                                <strong className="center-content">Silberne Ehrennadel für besondere Verdienste</strong>
                                <Figure className="center-content custom-width">
                                    <Figure.Image
                                        className='center-image'
                                        width={171}
                                        height={180}
                                        alt="171x180"
                                        src={brilliImage}
                                    />
                                    <Figure.Caption>
                                        <Row>
                                            <Col>
                                                <p>Sven Au&szlig;em</p>
                                                <p>Siegfried Borsch</p>
                                                <p>Robin Betzing</p>
                                                <p>Nicole Betzing</p>
                                                <p>Peter Gro&szlig;mann</p>
                                            </Col>
                                            <Col>
                                                <p>Werner Hamacher</p>
                                                <p>Helmut Klausnitzer</p>
                                                <p>Dieter Primus</p>
                                                <p>Berthold St&ouml;ckl</p>
                                            </Col>
                                        </Row>
                                    </Figure.Caption>
                                </Figure>
                                <strong className="center-content">Goldene Ehrennadel für hervorragende Leistung</strong>
                                <Figure className="center-content custom-width">
                                    <Figure.Image
                                        className='center-image'
                                        width={171}
                                        height={180}
                                        alt="171x180"
                                        src={rubinImage}
                                    />
                                    <Figure.Caption>
                                        <Row>
                                            <Col>
                                                <p>Heiner Betzing</p>
                                                <p>J&uuml;rgen Dederichs</p>
                                                <p>Heinz Bierther</p>
                                                <p>Helmut Giefer</p>
                                                <p>Hans-Willi Gottschalk</p>
                                            </Col>
                                            <Col>
                                                <p>Elke Hombach</p>
                                                <p>Johannes Hombach</p>
                                                <p>Vanessa Scheuermann</p>
                                                <p>J&uuml;rgen Lenzen</p>
                                            </Col>
                                        </Row>
                                    </Figure.Caption>
                                </Figure>
                                <strong className="center-content">Goldene Ehrennadel mit Brillanten für außerordentliche Leistung</strong>
                                <Figure className="center-content custom-width">
                                    <Figure.Image
                                        className='center-image'
                                        width={171}
                                        height={180}
                                        alt="171x180"
                                        src={saphierImage}
                                    />
                                    <Figure.Caption>
                                        <Row>
                                            <Col>
                                                <p>Heinz Hamacher</p>
                                                <p>Iris J&auml;ger</p>
                                                <p>Paul Hubert Kranzusch</p>
                                                <p>Andreas Nolte</p>
                                            </Col>
                                            <Col>

                                            </Col>
                                        </Row>
                                    </Figure.Caption>
                                </Figure>
                                <strong className="center-content">Verdienstnadel in Silber mit Brillanten für den persönlichen verdienstvollen langjährigen Einsatz</strong>
                                <Figure className="center-content custom-width">
                                    <Figure.Caption>
                                        <Row>
                                            <Col>
                                                <p>Christopher Au&szlig;em</p>
                                                <p>Franz Au&szlig;em</p>
                                                <p>G&uuml;nter Bott</p>
                                                <p>Brigitte D&uuml;sselberg</p>
                                                <p>Siegfried Hahn</p>
                                            </Col>
                                            <Col>
                                                <p>Hans-Willi Jansen &dagger;</p>
                                                <p>Michael Jansen</p>
                                                <p>Friedel Lenders</p>
                                                <p>Leo M&uuml;ller</p>
                                            </Col>
                                        </Row>
                                    </Figure.Caption>
                                </Figure>
                                <strong className="center-content">Verdienstnadel in Gold mit Brillanten für den persönlichen außerordentlichen Einsatz und verdienstvolle langjährige Mitarbeit</strong>
                                <Figure className="center-content custom-width">
                                    <Figure.Caption>
                                        <Row>
                                            <Col>
                                                <p>Martin Jansen</p>
                                                <p>Joachim Scheuermann</p>
                                                <p>Friedbert Hass</p>
                                            </Col>
                                            <Col>
                                            </Col>
                                        </Row>
                                    </Figure.Caption>
                                </Figure>
                            </Stack>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}
export default EhrenAdel;
